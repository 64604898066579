import { Injectable, TemplateRef } from '@angular/core';

type ToastPosition = 'center-center' | 'top-center' | 'bottom-center' | 'top-full-width' | 'bottom-full-width' | 'top-left' | 'top-right' | 'bottom-right' | 'bottom-left';

type ToastClassNames = 'success' | 'info' | 'danger' | 'warning';

interface ToastOptions {
  template?: TemplateRef<Element> | null;
  text?: string;
  delay?: number;
  className?: ToastClassNames;
  translated?: boolean;
  interpolation?: Record<string, string>;
  closable?: boolean;
  position?: ToastPosition;
  onClose?: () => void;
}

export class Toast {

  template?: TemplateRef<Element> | null;
  text?: string;
  delay?: number;
  className?: ToastClassNames;
  translated?: boolean;
  interpolation?: Record<string, string>;
  closable?: boolean;
  position?: ToastPosition;
  onClose?: () => void;

  constructor(options: ToastOptions) {
    if (options) {
      Object.assign(this, options);
    }
    this.delay = this.delay || 5000;
    this.position = this.position || 'top-right';
  }
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  toasts: Array<Toast> = [];

  show(text: string | Record<string, string>, options: Partial<Toast>) {
    if (typeof text === 'object') {
      Object.entries(text).forEach(([_key, text]) => {
        const toast = new Toast({ text, ...options });
        this.toasts.push(toast);
      });
    } else {
      const toast = new Toast({ text, ...options });
      this.toasts.push(toast);
    }
  }

  remove(toast: Toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }
}
